import axios from 'axios';
import axiosIntents from '@/utils/axios-intents'
import store from '@/store'

import errMessage from '@/helpers/err-message'
import { firstLetterToUpperCase } from '@/utils/common.js'

import i18n from '@/i18n'

const API_V1 = 'v1';
const API_V2 = 'v2';
const API_V3 = 'v3';
const SPID = +process.env.VUE_APP_SPID;

export default {
  	state: {},

  	getters: {},

  	actions: {
    	onboardingAuthCheck({}, { firebaseId, firebaseToken }) {
      		return axios.post(`${API_V2}/${SPID}/auth_check`, {
      		  SPID: SPID,
      		  IdentityProvider: 'FIREBASE',
      		  AuthID: firebaseId,
      		  AuthCode: firebaseToken,
      		  IncludeRoles: true,
      		}, { is_auth_check: true }).catch(error => {
                console.log('onboardingAuthCheck Error', error)
                return {}
            });
    	},

		onboardingAdoptByIp({ dispatch }, JWT) {
			return axios.post(
				`${API_V3}/adopt_by_ip`,
				{
				  SPID: SPID,
				},
				{
				  headers: {
					'x-auth-token': JWT,
				  },
				},
			  )
              .then(resp => {
                console.log('onboardingAdoptByIp', resp)
                if (!(resp.data) || !(resp.data.data)) {
                    console.log('onboardingAdoptionTokenResume: No data')
                    return Promise.resolve(resp)
                }
                return dispatch('UpdateFirebaseAccess', { data: resp.data }).then(_ => {
                    return Promise.resolve(resp)
                }).catch(error => {
                    console.log('UpdateFirebaseAccess Error', error)
                    return Promise.resolve(resp)
                })
              });
		},
	
		onboardingAdoptByToken({ dispatch }, { JWT, token }) {
			return axios.post(
				`${API_V3}/adopt_by_token`,
				{
				  SPID: SPID,
				  Token: token,
				},
				{
				  headers: {
					'x-auth-token': JWT,
				  },
				},
			)
            .then(resp => {
                console.log('onboardingAdoptByToken', resp)
                if (!(resp.data)) {
                    console.log('onboardingAdoptByToken: No data')
                    return Promise.resolve(resp)
                }
                return dispatch('UpdateFirebaseAccess', { data: resp.data }).then(_ => {
                    return Promise.resolve(resp)
                }).catch(error => {
                    console.log('UpdateFirebaseAccess Error', error)
                    return Promise.resolve(resp)
                })
            });
		},

		onboardingAdoptionTokenResume({ dispatch }, { onboardingAdoptionResume_JWT, adoption_uuid }) {
            return axios.get(`${API_V3}/adoption_token_resume/${adoption_uuid}?SPID=${SPID}`, {
				headers: {
				  'x-auth-token': onboardingAdoptionResume_JWT,
				},
			})
            .then(resp => {
                console.log('onboardingAdoptionTokenResume', resp)
                if (!(resp.data)) {
                    console.log('onboardingAdoptionTokenResume: No data')
                    return Promise.resolve(resp)
                }
                return dispatch('UpdateFirebaseAccess', { data: resp.data }).then(_ => {
                    return Promise.resolve(resp)
                }).catch(error => {
                    console.log('UpdateFirebaseAccess Error', error)
                    return Promise.resolve(resp)
                })
            });
		},

		onboardingAdoptBySms({ dispatch }, { phone, is_sim_id, SIMID, ICCID, code, JWT }) {
			const data = is_sim_id ? { SPID, SIMID, ICCID } : { SPID, MobileNumber: phone };

            const request_path = (is_sim_id) ? 'adopt_by_sim_sms' : 'adopt_by_sms';

			if (code) data.SMSCode = code;

			return axios.post(`${API_V3}/${request_path}`, data, {
			  headers: {
			    'x-auth-token': JWT,
			  },
			})
            .then(resp => {
                if (code) {
                    console.log('onboardingAdoptBySms', resp)
                    return dispatch('UpdateFirebaseAccess', { data: resp.data }).then(_ => {
                        return Promise.resolve(resp)
                    }).catch(error => {
                        console.log('UpdateFirebaseAccess Error', error)
                        return Promise.resolve(resp)
                    })
                }
                return Promise.resolve(resp)
            });
		},

        onboardingRedeemVoucherCheck({getters, dispatch}, VoucherCode) {
            const CustomerUUID = getters.current_account_uid
            const ProductInstanceUUID = getters.customer_current_product_uuid

            if (!CustomerUUID || !ProductInstanceUUID || !VoucherCode) return Promise.reject('Redemption check Error')

            return axiosIntents.post(`/customer/${CustomerUUID}/check_voucher/${ProductInstanceUUID}/${VoucherCode}`).then(({result}) => {
                if (!result) {
                    return Promise.reject('No response')
                }

                console.log('check_voucher result', result)

                // Top-up by Voucher
                /* if (!result.Attributes?.MobileProductAvailabilityUUID) {
                    return dispatch('onboardingRedeemVoucher', VoucherCode)
                } */

                return Promise.resolve(result)
            }).catch(error => {
                console.log('onboardingRedeemVoucherCheck error', error)

                return Promise.reject(error)
            })
        },

        onboardingRedeemVoucher({getters}, VoucherCode) {
            const CustomerUUID = getters.current_account_uid
            const ProductInstanceUUID = getters.customer_current_product_uuid

            if (!CustomerUUID || !ProductInstanceUUID || !VoucherCode) return Promise.reject('Redemption Error')

            return axiosIntents.post(`/customer/${CustomerUUID}/redeem_voucher/${ProductInstanceUUID}/${VoucherCode}`).then(({result}) => {
                console.log('redeem_voucher result', result)

                return Promise.resolve(result)
            }).catch(error => {
                console.log('onboardingRedeemVoucher error', error)

                return Promise.reject(error)
            })
        },

        onboardingDryActivation({}, { CustomerUUID, ProductInstanceUUID, PaymentMethodID, PaymentMethodType, Dry } = {}) {
            console.log('onboardingDryActivation request Dry', Dry)
            console.log('onboardingDryActivation request CustomerUUID', CustomerUUID)
            console.log('onboardingDryActivation request ProductInstanceUUID', ProductInstanceUUID)
            console.log('onboardingDryActivation request PaymentMethodID', PaymentMethodID)
            console.log('onboardingDryActivation request PaymentMethodType', PaymentMethodType)

            return axiosIntents.post(`/customer/${CustomerUUID}/service/${ProductInstanceUUID}/activate/${Dry}`, { 
                PaymentMethodID,
                PaymentMethodType,
                PaymentMethodGateway: PaymentMethodType == 'cards' ? 'stripe' : '',
                AuthorizeOnly: true,
            }).then(rslt => {
                console.log('onboardingDryActivation rslt.data', rslt.data)

                if (rslt.data?.ClientSecret && rslt.data?.PaymentIntent && !Dry) {
                    return Promise.resolve({
                        is_on_dry_activation: true,
                        has_payment_method_error: false, 
                        ClientSecret: rslt.data?.ClientSecret,
                        PaymentIntent: rslt.data?.PaymentIntent,
                        dry_activated_product_instance_uuid: rslt.data?.ProductInstanceUUID,
                    })
                }

                if (rslt.status == 200) {
                    // 200 = ok, we will preactivate using pre-purchased data
                    console.log('onboardingDryActivation: auto onboarding, have payment method')
                } else if (rslt.status == 201) {
                    // 201 = continue, there is no need to preactivate
                    console.log('onboardingDryActivation: already onboarded')
                } else {
                    // other 2xx (unexpected)
                    console.log('onboardingDryActivation: auto onboarding ...')
                }

                return Promise.resolve({
                    is_on_dry_activation: true,
                    has_payment_method_error: false,
                    dry_activated_product_instance_uuid: rslt.data?.ProductInstanceUUID,
                })
            }).catch(error => {
                console.log('onboardingDryActivation error status', error.response?.status)

                const error_status = error.response?.status

                if (error_status == 412) {
                    // 412 = need to choose a plan
                    console.log('onboardingDryActivation: standard onboarding')
                    return Promise.resolve({ is_on_dry_activation: false, has_payment_method_error: false })
                }

                if (error_status >= 400 && error_status < 500) {
                    // 402 = we can auto-activate, but we need to enter a payment method
                    if (error_status == 402) {
                        console.log('onboardingDryActivation: auto onboarding, need payment method')
                    }

                    const dry_activation_error_message = error.response?.data?.err_message ? `We cannot proceed with your Activation, ${error.response.data.err_message}` : `We cannot proceed with your Activation. Please try again later`

                    return Promise.resolve({ is_on_dry_activation: true, has_payment_method_error: true,  dry_activation_error_message })
                }

                // TODO: 500 errors here
                console.log('onboardingDryActivation: onboarding '+(error.response?.status)+' ...')
                return Promise.resolve({ is_on_dry_activation: false, has_payment_method_error: false })
            })
        },

        transferProductTypeDryActivation({ getters }, { TransferProductType, Dry } = {}) {
            const CustomerUUID = getters.current_account_uid
            const ProductInstanceUUID = getters.customer_current_product_uuid

            return axiosIntents.put(`/sim/v1/product-type/${Dry}`, {
                CustomerUUID,
                ProductInstanceUUID,
                Type: TransferProductType,
            })
        },

		async onboardingActivation({ dispatch }, { plan = {}, finalisation_type, JWT, isCasual, planUUID, planAvailabilityUUID, productUuid, customerUuid, PaymentMethodID, PaymentMethodType, Subscribe = true }) {
            console.log('onboardingActivation finalisation_type', finalisation_type)
            console.log('onboardingActivation productUuid', productUuid)

            if (finalisation_type == 'dry_activation') {
                return dispatch('getMobileProductFromFirebase', { current_account_uid: customerUuid, moile_product_uuid: productUuid })
            } else if (finalisation_type == 'voucher_redeem_activation') {
                const activating_by_voucher_redeem_response = JSON.parse(sessionStorage.getItem('activating_by_voucher_redeem_response'))

                const VoucherCode = activating_by_voucher_redeem_response?.code

                console.log('onboardingActivation VoucherCode', VoucherCode)

                if (!VoucherCode) {
                    return Promise.reject('No Voucher Code')
                }

                return dispatch('onboardingRedeemVoucher', VoucherCode).then(() => {
                    return dispatch('getMobileProductFromFirebase', { current_account_uid: customerUuid, moile_product_uuid: productUuid })
                })
            } else if (finalisation_type == 'postpay') {
                const activated_product_with_addons =  await dispatch('getActivatedProductWithAddons', { CustomerUUID: customerUuid, ProductInstanceUUID: productUuid })

                console.log('postpay onboardingActivation activated_product_with_addons', activated_product_with_addons)

                if (activated_product_with_addons) {
                    return Promise.resolve(activated_product_with_addons)
                }

				return axiosIntents.post(`/postpay/activation`,
                    {
                        Subscribe,
                        ...(PaymentMethodID && {PaymentMethodID}),
                        ...(PaymentMethodType && {PaymentMethodType}),
                        PaymentMethodGateway: PaymentMethodType == 'cards' ? 'stripe' : '',
						"CustomerUUID": customerUuid,
						"MobileProductUUID": productUuid,
						"ProductAvailabilityUUID": planAvailabilityUUID,
						"ProductCatalogUUID": planUUID,
					},
                    { headers: { 'x-auth-token': JWT, }, },
                )
                .then(result => {
                    const client_secret = result.data?.ClientSecret

                    if (client_secret) {
                        return dispatch('onboardingPurchaseConfirmation', { card_token: PaymentMethodType == 'cards' ? PaymentMethodID : '', client_secret }).catch(error => {
                            // TODO check if error needs to be returned
                            console.log('onboardingPurchaseConfirmation Error', error)
                        })
                    }
                })
                .then(() => {
                    return dispatch('getMobileProductFromFirebase', { current_account_uid: customerUuid, moile_product_uuid: productUuid })
                })
                /* .then(postpayProductUUID => {
                    return dispatch('onboardingGetPostPayProduct', { JWT, customerUuid, productUuid: postpayProductUUID })
                }) */
            } else if (finalisation_type == 'prepay' && !isCasual) {
                return dispatch('getMobileProductFromFirebase', { current_account_uid: customerUuid, moile_product_uuid: productUuid })
                /* .then(prepayProductUUID => {
                    return dispatch('onboardingGetPrePayProduct', { JWT, customerUuid, productUuid: prepayProductUUID })
                }) */
            } else if (finalisation_type == 'prepay' && isCasual) {
                return axios.post(`${API_V3}/${SPID}/prepay_mobile/finalise_activation`,
                    { MobileProductUUID: productUuid, CustomerUUID: customerUuid },
                    { headers: { 'x-auth-token': JWT, }, },
                )
            } else {
                return Promise.reject('Activation Error')
            }
		},

		/* onboardingActivationPrePay({}, { JWT, productUuid, customerUuid }) {
			return axios.post(
				`${API_V3}/${SPID}/customer/${customerUuid}/mobileprepay/${productUuid}/finalise_activation`,
				{ SPID: SPID, UUID: productUuid, CustomerUUID: customerUuid },
				{
				  headers: {
					'x-auth-token': JWT,
				  },
				},
			);
		}, */

		onboardingNumberPorting({}, payload = {}) {
            return axiosIntents.put('mobile_porting', payload).catch(error => {
                const err_code = error?.response?.data?.err_code

                if (err_code >= 400 && err_code < 600 && err_code != 500) {
                    return Promise.reject({ message: firstLetterToUpperCase(errMessage(error)) })
                }

                return Promise.reject({ message: i18n.t('try_again_later') })
            });
		},

        onboardingResendNumberPortingSMS({ getters }) {
            const CustomerUUID = getters.current_account_uid
            const MobileUUID = getters.customer_current_product_uuid

            return axiosIntents.put('/mobile_porting_retry', {
                CustomerUUID,
                MobileUUID,
            }).catch(error => {
                const err_code = error?.response?.data?.err_code

                if (err_code >= 400 && err_code < 600 && err_code != 500) {
                    return Promise.reject(firstLetterToUpperCase(errMessage(error)))
                }

                return Promise.reject(i18n.t('try_again_later'))
            });
        },

        onboardingNumberPortingCancel({ getters }) {
            const CustomerUUID = getters.current_account_uid
            const MobileUUID = getters.customer_current_product_uuid

            return axiosIntents.put(`/customer/${CustomerUUID}/cancel_mobile_porting`, {
                MobileUUID,
                SPID,
            }).catch(error => {
                const err_code = error?.response?.data?.err_code

                if (err_code >= 400 && err_code < 600 && err_code != 500) {
                    return Promise.reject(firstLetterToUpperCase(errMessage(error)))
                }

                return Promise.reject(i18n.t('try_again_later'))
            });
        },

        onboardingPurchaseConfirmation({ dispatch }, { card_token, client_secret }) {
            return dispatch('stripePaymentConfirmation', { card_token, client_secret })
        },

        // response.data?.ChildProductInstanceUUID

        getMobileProductFromFirebase({ dispatch, getters }, { current_account_uid, moile_product_uuid }){
            console.log('getMobileProductFromFirebase current_account_uid', current_account_uid)
            console.log('getMobileProductFromFirebase moile_product_uuid', moile_product_uuid)

            if (getters.is_app_environment_billing_routing_allowed) {
                dispatch('BindBillingRoutes', current_account_uid)
            }
            
            return dispatch('BindCustomerAllProducts', current_account_uid).then(()=> {
                return new Promise((resolve, reject) => {
                    let unwatch

                    console.log('getMobileProductFromFirebase getters.customer_mobiles', getters.customer_mobiles)
        
                    const mobile_product = getters.customer_mobiles.find(product => product.UUID = moile_product_uuid)

                    console.log('getMobileProductFromFirebase mobile_product', mobile_product)
        
                    if (mobile_product && mobile_product.Addons && mobile_product.Addons.length) {
                        console.log('Firebase mobile_product', mobile_product)

                        resolve(mobile_product)
                    } else {
                        unwatch = store.watch((state, getters) => getters.customer_mobiles, 
                            mobile_products => {
                                console.log('getMobileProductFromFirebase watch mobile_products', mobile_products)

                                const mobile_product = mobile_products.find(product => product.UUID = moile_product_uuid)

                                console.log('getMobileProductFromFirebase watch mobile_product', mobile_products)
        
                                if (mobile_product && mobile_product.Addons && mobile_product.Addons.length) {
                                    console.log('Firebase mobile_product', mobile_product)

                                    unwatch()
                                    resolve(mobile_product)
                                }
                            })
                    }
        
                    setTimeout(() => {
                        if (unwatch) { unwatch() }
        
                        reject('Timout')
                    }, 250000);
                })
            })
        },

		onboardingGetPrePayProduct({}, { JWT, productUuid, customerUuid }) {
			return axios.get(`${API_V3}/${SPID}/customer/${customerUuid}/mobileprepay/${productUuid}`, {
				headers: {
				  'x-auth-token': JWT,
				},
			});
		},

		onboardingGetPostPayProduct({}, { JWT, productUuid, customerUuid }) {
			return axios.get(`${API_V1}/${SPID}/customer/${customerUuid}/postpay_mobile/${productUuid}`, {
				headers: {
				  'x-auth-token': JWT,
				},
			});
		},

        // mock all providers: axios.get(`${API_V3}/${SPID}/ipms/get_service_providers`
        onboardingGetServiceProviders({ getters }, PhoneNumber) {
            return axios.get(`${API_V3}/${SPID}/ipms/service_provider_lookup`, {
                params: { 
                    PhoneNumber,
                },
			});
        },

        onboardingGetEphemeralToken({}, { JWT, ExtraToken }) {
            return axios.post(
                '/v2/auth_token', 
                { 
                    ExtraData1: ExtraToken,
                }, 
                { 
                    headers: { 
                        'x-auth-token': JWT, 
                    }, 
                },
            ).catch(error => {
                console.log('onboardingGetEphemeralToken Error', error)
                return {}
            })
        },

        mobileOnboardingGetCustomToken({}, UUID) {
            return axios.get(`/v2/auth_token/${UUID}`, { params: { noauth: true } })
        },

        getprePurchasedServiceAutoRenew({ getters }) {
            const MobileUUID = getters.customer_current_product_uuid

            return axiosIntents.get(`/sim/metadata/${MobileUUID}`)
        },
  	},

  	mutations: {},
};
