import Vue from 'vue'
import axiosIntents from '@/utils/axios-intents'

import StoreHash from '@/utils/StoreHash'

let cashed = {
    invoices_v3: {
        cashes: {
            // [status]: {
            //     [limit]: [StoreHash, null, StoreHash, ...],
            // },
        },
        lifetime: 5 * 60 * 1000, // 5 minutes
    }
}

import { INVOICES_LIMIT_DEFAULT, INVOICE_STATUSES } from '@/helpers/invoices'

const invoicesCashedExpired_v3 = (status, limit, page) =>
    (cashed.invoices_v3.cashes?.[status]?.[limit]?.length || 0) < page--
    || cashed.invoices_v3.cashes[status][limit][page] == null
    || cashed.invoices_v3.cashes[status][limit][page].expired()

// invoices_v3[status] = {
//     total: 10000,
//     limits: {
//         [limit]: [[...], [], [...], ...],
//         ...
//     },
//     summary: {...},
// }
// invoices_v3[status].total = 10000
// invoices_v3[status].limits[limit] = [[...], [], [...], ...]

// invoices_v3_loading[status] = {
//     [limit]: [Promise, null, Promise, ...],
//     ...
// }
// invoices_v3_loading[status][limit][page] = [Promise, null, Promise, ...]
// invoices_v3_loading_error[status][limit][page] = [Error, null, Error, ...]

const state = {
    invoices_v3: {},
    invoices_v3_loading: {},
    invoices_v3_loading_error: {},
}

const getters = {
    invoices_v3: state => (page, status, limit = INVOICES_LIMIT_DEFAULT) => state.invoices_v3?.[status]?.limits?.[limit]?.length > --page ? state.invoices_v3[status].limits[limit][page] : [],
    invoicesFlat_v3: state => (status, limit = INVOICES_LIMIT_DEFAULT) => state.invoices_v3?.[status]?.limits?.[limit]?.flat() || [],

    invoicesCurrentPeriod_v3: (state, getters) => (status, limit = INVOICES_LIMIT_DEFAULT) => getters.invoicesFlat_v3(status, limit).filter(invoice => invoice.Status.toUpperCase() == INVOICE_STATUSES.OPEN.status),
    invoicesPreviousPeriod_v3: state => (page, status, limit = INVOICES_LIMIT_DEFAULT) => state.invoices_v3?.[status]?.limits?.[limit]?.length > --page ? state.invoices_v3[status].limits[limit][page].filter(invoice => invoice.Status.toUpperCase() != INVOICE_STATUSES.OPEN.status) : [],

    invoicesTotal_v3: state => (status) => state.invoices_v3?.[status]?.total || 0,
    invoicesTotalPages_v3: (state, getters) => (status, limit = INVOICES_LIMIT_DEFAULT) => Math.ceil(getters.invoicesTotal_v3(status) / limit),

    invoicesSummary_v3: state => (status) => state.invoices_v3?.[status]?.summary || {},

    invoicesLoading_v3: state => (page, status, limit = INVOICES_LIMIT_DEFAULT) => state.invoices_v3_loading?.[status]?.[limit]?.length > --page ? state.invoices_v3_loading[status][limit][page] : null,
    isInvoicesLoading_v3: state => (status, limit = INVOICES_LIMIT_DEFAULT) => state.invoices_v3_loading?.[status]?.[limit]?.length ? state.invoices_v3_loading[status][limit].findIndex(invoice_v3_loading => invoice_v3_loading !== null) > -1 : false,
    isInvoicesLoadingPage_v3: (state, getters) => (page, status, limit = INVOICES_LIMIT_DEFAULT) => Boolean(getters.invoicesLoading_v3(page, status, limit)),

    invoicesLoadingError_v3: state => (page, status, limit = INVOICES_LIMIT_DEFAULT) => state.invoices_v3_loading_error?.[status]?.[limit]?.length > --page ? state.invoices_v3_loading_error[status][limit][page] : null,

    invoice_v3: state => number => {
        let invoice_v3 = null

        if (state.invoices_v3?.[number]?.limits?.[INVOICES_LIMIT_DEFAULT]?.[0]?.length
            && !invoicesCashedExpired_v3(number, INVOICES_LIMIT_DEFAULT, 1)
        ) {
            invoice_v3 = state.invoices_v3[number].limits[INVOICES_LIMIT_DEFAULT][0][0]
        } else {
            invoice:
            for (const status in state.invoices_v3) {
                for (const limit in state.invoices_v3[status].limits) {
                    for (let i = 0; i < state.invoices_v3[status].limits[limit].length; i++) {
                        if (!invoicesCashedExpired_v3(status, limit, i + 1)) {
                            const index = state.invoices_v3[status].limits[limit][i].findIndex(invoice => invoice.Number == number)

                            if (index > -1) {
                                invoice_v3 = state.invoices_v3[status].limits[limit][i][index]
                                break invoice
                            }
                        }
                    }
                }
            }
        }

        return invoice_v3
    },
}

const actions = {
    getInvoices_v3({getters, commit}, {
        status = undefined,
        limit = INVOICES_LIMIT_DEFAULT,
        page = 1,
        force = false,
        number = null,
    } = {}) {
        const options = {
            status,
            limit,
            page,
            force,
        }

        if (getters.is_app_environment_invoices_allowed) {
            const invoices_v3_loading = getters.invoicesLoading_v3(page, status, limit)

            if (invoices_v3_loading) {
                return invoices_v3_loading
            } else if (force || invoicesCashedExpired_v3(status, limit, page)) {
                const invoice_v3_loading = (number
                    ? axiosIntents.get(`/v3/customer/:uuid/invoices/${ number }`)
                    : axiosIntents.get(`/v3/customer/:uuid/invoices`, { params: {
                        Status: status,
                        PageSize: limit,
                        PageNumber: page,
                    } })
                ).then(({data}) => {
                    if (invoice_v3_loading === getters.invoicesLoading_v3(page, status, limit)) {
                        commit('setInvoicesLoadingError_v3', (options.error = null, options))

                        commit('setInvoices_v3', ((number ? (
                                options.invoices = [data],
                                options.total = 1
                            ) : (
                                options.invoices = data.Invoices,
                                options.total = data.PageInfo.TotalItemCount,
                                options.summary = Object.keys(data).reduce((summary, key) => key === 'Invoices' || key === 'PageInfo' ? summary : (summary[key] = data[key], summary), {})
                            )), options
                        ))

                        return Promise.resolve(getters.invoices_v3(page, status, limit))
                    }
                }).catch(error => {
                    if (invoice_v3_loading === getters.invoicesLoading_v3(page, status, limit)) {
                        commit('setInvoicesLoadingError_v3', (options.error = error, options))
                    }

                    return number
                        ? Promise.reject(error)
                        : Promise.resolve([])
                }).finally(()=> {
                    if (invoice_v3_loading === getters.invoicesLoading_v3(page, status, limit)) {
                        commit('setInvoicesLoading_v3', (options.invoice_v3_loading = null, options))
                    }
                })

                commit('setInvoicesLoading_v3', (options.invoice_v3_loading = invoice_v3_loading, options))

                return invoice_v3_loading
            } else {
                return Promise.resolve(getters.invoices_v3(page, status, limit))
            }
        }
    },

    getInvoice_v3({getters, dispatch}, number) {
        return dispatch('getInvoices_v3', { number, status: number }).then(() => Promise.resolve(getters.invoice_v3(number)))
    },
}

const mutations = {
    setInvoices_v3(state, { status, limit, page, invoices, total, summary }) {
        if (!state.invoices_v3.hasOwnProperty(status)) {
            Vue.set(state.invoices_v3, status, {
                total,
                limits: {
                    [limit]: new Array(),
                },
                summary,
            })

            cashed.invoices_v3.cashes[status] = {
                [limit]: new Array(),
            }
        } else if (!state.invoices_v3[status].limits.hasOwnProperty(limit)) {
            Vue.set(state.invoices_v3[status].limits, limit, new Array())

            cashed.invoices_v3.cashes[status][limit] = new Array()
        }

        if (--page < state.invoices_v3[status].limits[limit].length) {
            state.invoices_v3[status].limits[limit].splice(page, 1, invoices)
        } else {
            if (page > state.invoices_v3[status].limits[limit].length) {
                for (let i = state.invoices_v3[status].limits[limit].length; i < page; i++) {
                    state.invoices_v3[status].limits[limit].push([])

                    cashed.invoices_v3.cashes[status][limit].push(null)
                }
            }

            state.invoices_v3[status].limits[limit].push(invoices)
        }

        if (state.invoices_v3[status].total !== total) {
            if (total < state.invoices_v3[status].total) {
                const limits = [
                    state.invoices_v3[status].limits,
                    state.invoices_v3_loading[status],
                    state.invoices_v3_loading_error[status],
                    cashed.invoices_v3.cashes[status],
                ]

                for (let i = 0; i < limits.length; i++) {
                    for (const limit in limits[i]) {
                        const invoices_page_limit_v3 = Math.ceil(total / limit)

                        if (limits[i][limit].length > invoices_page_limit_v3) {
                            limits[i][limit].length = invoices_page_limit_v3
                        }
                    }
                }
            }

            state.invoices_v3[status].total = total
        }

        state.invoices_v3[status].summary = summary

        if (!cashed.invoices_v3.cashes[status][limit][page]) {
            cashed.invoices_v3.cashes[status][limit][page] = new StoreHash(cashed.invoices_v3.lifetime)
        }
        cashed.invoices_v3.cashes[status][limit][page].fix()
    },

    setInvoicesLoading_v3(state, { status, limit, page, invoice_v3_loading }) {
        if (!state.invoices_v3_loading.hasOwnProperty(status)) {
            Vue.set(state.invoices_v3_loading, status, {
                [limit]: new Array(),
            })
        } else if (!state.invoices_v3_loading[status].hasOwnProperty(limit)) {
            Vue.set(state.invoices_v3_loading[status], limit, new Array())
        }

        if (--page < state.invoices_v3_loading[status][limit].length) {
            state.invoices_v3_loading[status][limit].splice(page, 1, invoice_v3_loading)
        } else {
            if (page > state.invoices_v3_loading[status][limit].length) {
                for (let i = state.invoices_v3_loading[status][limit].length; i < page; i++) {
                    state.invoices_v3_loading[status][limit].push(null)
                }
            }

            state.invoices_v3_loading[status][limit].push(invoice_v3_loading)
        }
    },

    setInvoicesLoadingError_v3(state, { status, limit, page, error }) {
        if (!state.invoices_v3_loading_error.hasOwnProperty(status)) {
            Vue.set(state.invoices_v3_loading_error, status, {
                [limit]: new Array(),
            })
        } else if (!state.invoices_v3_loading_error[status].hasOwnProperty(limit)) {
            Vue.set(state.invoices_v3_loading_error[status], limit, new Array())
        }

        if (--page < state.invoices_v3_loading_error[status][limit].length) {
            state.invoices_v3_loading_error[status][limit].splice(page, 1, error)
        } else {
            if (page > state.invoices_v3_loading_error[status][limit].length) {
                for (let i = state.invoices_v3_loading_error[status][limit].length; i < page; i++) {
                    state.invoices_v3_loading_error[status][limit].push(null)
                }
            }

            state.invoices_v3_loading_error[status][limit].push(error)
        }
    },

    
    

    resetInvoices_v3(state) {
        state.invoices_v3 = {}
        state.invoices_v3_loading = {}
        state.invoices_v3_loading_error = {}

        cashed.invoices_v3.cashes = {}
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
